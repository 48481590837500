.rotatingText {
  transition: all 1.5s;
  width: clamp(100px, 90%, 1200px);
}

.rotatingText-adjective {
  font-size: clamp(5.5rem, 22vw, 22rem);
  font-weight: 700;
  letter-spacing: 0vw;
  line-height: clamp(1.1rem, 5vw, 9rem);
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
}

.rotatingText-adjective:nth-of-type(1) {
  font-family: Times;
  animation-name: rotate;
  animation-duration: 1.3s;
  animation-delay: 0s;
}

.rotatingText-adjective:nth-of-type(2) {
  font-family: "EXO", sans-serif;
  font-weight: 200;
  animation-name: rotate;
  animation-duration: 1.1s;
  animation-delay: 1.1s;
}

.rotatingText-adjective:nth-of-type(3) {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 900;
  animation-name: rotate;
  animation-duration: 0.9s;
  animation-delay: 2.1s;
}

.rotatingText-adjective:nth-of-type(4) {
  font-family: "Times", sans-serif;
  font-weight: 900;
  animation-name: rotate;
  animation-duration: 0.7s;
  animation-delay: 2.9s;
}

.rotatingText-adjective:nth-of-type(5) {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  animation-name: rotate;
  animation-duration: 0.8s;
  animation-delay: 3.7s;
}

.rotatingText-adjective:nth-of-type(6) {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 200;
  animation-name: rotate-last;
  animation-duration: 0.7s;
  animation-delay: 4.4s;
  animation-fill-mode: forwards;
}

@keyframes rotate {
  0% {
    opacity: 0;
    transform: translate3d(0, 50px, 0);
  }

  20%,
  80% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  100% {
    opacity: 0;
    transform: translate3d(0, -25px, 0);
  }
}

@keyframes rotate-last {
  0% {
    opacity: 0;
    transform: translate3d(0, 50px, 0);
  }

  50%,
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
